$color_1: '#212B36';
$color_2: '#000000';
$color_3: #000000;
$color_4: #9ca3af;
$color_5: #212b36;
$color_6: #ffffff;
$color_7: #0f172a;
$color_8: #637381;
$color_9: #f2f5f9;
$background-color_1: #ffffff;
$background-color_2: #d75c57;
$background-color_3: rgba(255, 255, 255, 0.4);

.dashboardFirstRowWrapper {
  width: 88vw;
  display: flex;
  flex-direction: row;
  // margin-bottom: 200px;
  // align-items: center;
  justify-content: flex-start;
}
.dashboardFirstColWrapper {
  width: 100%;
  // flex-direction: row;
  // display: flex;
  // margin-bottom: 200px;
  // align-items: center;
  justify-content: flex-start;
  background-color: green;
}
.playlistCardWrapper {
  width: 58%;
  min-height: 60vh;
  background-color: $background-color_1;
  overflow: hidden;
  border-radius: 20px;
  padding: 30px;
  margin-right: 20px;
  margin-bottom: 110px;
}
.playlistCardNewWrap {
  width: 58%;
  border-radius: 20px;
  margin-right: 20px;
  height: 170vh;
  margin-bottom: 60px;
}
.playlistCardInside {
  width: 100%;
  background-color: $background-color_1;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 20px;

  // min-height: 60vh;
  // overflow: hidden;
  // border-radius: 20px;
  // padding: 30px;
  // margin-right: 2%;
}
.supportCardWrapper {
  width: 58%;
  height: 800px;
  background-color: $background-color_1;
  overflow: hidden;
  border-radius: 20px;
  padding: 30px;
  margin-right: 20px;
}

.paymentCardTopWrapper {
  width: 58%;
  padding: 0 10px;
}
.paymentCardWrapper {
  width: 100%;
  background-color: $background-color_1;
  border-radius: 20px;
  pointer-events: auto;
  position: relative;
  height: 700px;
  overflow: hidden;
}

.title {
  font-size: 20px;
  color: $color_1;
  font-weight: 700;
  letter-spacing: 1px;
}
.contentRow {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 10px;
}
.contentCol1 {
  width: 50%;
  // height: 100%;
  padding: 24px;
  background-color: #fcfcfc;
  border-radius: 10px;
}
.contentCol1HeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.contentCol1HeaderRowCol1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contentCol1HeaderRowCol2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.inOfficeIcon {
  margin-right: 10px;
}
.inofficeText {
  font-size: 16px;
  color: $color_1;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.inOfficeIconImg {
  width: 20px;
  height: 14px;
}
.Col2IdNumber {
  margin-right: 10px;
  font-size: 18px;
  color: $color_1;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.infoIcon {
  height: 100%;
  position: relative;
  top: 3px;
  right: 8px;
}
.infoIconImg {
  width: 20px;
  height: 20px;
}
.teamMembersBox {
  background-color: red;
  padding: 24px;
  background-color: #fcfcfc;
  border-radius: 10px;
}
.playlistGradientCardWrapper {
  margin-top: 10px;
  min-height: 25vh;
  border-radius: 10px;
  background-image: url('../img/welcomeVideo.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.photo-collage {
  cursor: pointer;
  margin-top: 5px;
  border-radius: 20px;
  overflow-x: hidden;
}
.cardBottomWrapper {
  margin-top: 10px;
  div {
    font-size: 22px;
    color: $color_1;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-right: 10px;
  }
}
.cardBottomTitle {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.editIconImg {
  width: 20px;
  height: 20px;
}
.cardBottomDevicesText {
  display: flex;
  align-items: center;
  margin-top: 12px;
  p {
    width: 5px;
    height: 5px;
    border-radius: 100px;
    background-color: $background-color_2;
    margin-right: 5px;
  }
  div {
    font-size: 12px;
    color: $color_2;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
}

.cardBottomDevicesTextConnected {
  display: flex;
  align-items: center;
  margin-top: 12px;
  p {
    width: 5px;
    height: 5px;
    border-radius: 100px;
    background-color: green;
    margin-right: 5px;
  }
  div {
    font-size: 12px;
    color: $color_2;
    font-weight: 300;
    letter-spacing: 0.5px;
  }
}

.cardBottomButtonsWrapper {
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  position: relative;
}
.cardBottomButton1 {
  width: 60%;
  height: 5.5vh;
  border-radius: 100px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 15px;
    color: $color_2;
    font-weight: 700;
  }
}
.cardBottomButton2 {
  width: 40%;
  height: 5.5vh;
  border-radius: 100px;
  border: 2px solid #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-size: 15px;
    color: $color_3;
    font-weight: 700;
  }
}

.cardBottomButtonDisabled {
  padding: 10px 20px;
  height: 5.5vh;
  border-radius: 100px;
  border: 2px solid #9ca3af;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  span {
    font-size: 15px;
    color: $color_4;
    font-weight: 700;
  }
}

.cardBottomButtonActive {
  padding: 10px 20px;
  height: 5.5vh;
  border-radius: 100px;
  border: 2px solid #9ca3af;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  span {
    font-size: 15px;
    color: $color_3;
    font-weight: 700;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  font-size: 12px;
  background-color: #fff;
  color: #000;
  border: solid 1px #c0c0c0;
  text-align: left;
  border-radius: 20px;
  padding: 10px 6px;
  position: absolute;
  z-index: 1;
  bottom: -10px;
  left: 155%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 40%;
  left: -2%;
  transform: rotate(90deg);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid 1px;
  border-color: #c0c0c0 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.contentCol2 {
  width: 47.5%;
  min-height: 40vh;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1vw;
  align-self: flex-start;
  background-color: #fcfcfc;
  border-radius: 10px;
}
.contentCol2SmallText {
  font-size: 12px;
  color: $color_5;
  font-weight: 300;
  margin-bottom: 5px;
  line-height: 30px;
}
.historyText {
  font-size: 12px;
  color: $color_5;
  font-weight: 300;
  margin-bottom: 5px;
  line-height: 1;
}
.contentCol2LargeText {
  font-size: 18px;
  color: $color_5;
  font-weight: 600;
  line-height: 25px;
}
.attentionCardWrapper {
  width: 38%;
  min-height: 5vh;
  background-color: $background-color_1;
  overflow: hidden;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 22px;
}

.attentionStarCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.attentionStarWrapper {
  width: 2.2vw;
  height: 2.2vw;
  margin-right: 10px;
  background-image: url('../img/star.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  align-items: center;
  justify-content: center;
  display: flex;
  div {
    font-size: 15px;
    color: $color_6;
    font-weight: 600;
  }
}
.attentionCardWrapperContent {
  &::-webkit-scrollbar {
    display: none;
  }
  // height: 20vh;
  padding: 0px 1.2vw;
  // display: flex;
  // flex-direction: column;
  padding-top: 2vh;
  align-items: center;
  justify-content: space-between;
  overflow-y: scroll;
  box-sizing: content-box;
}
.AttentionInnerCardWrapper {
  width: 100%;
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: blue;
}
.AttentionInnerCardLogoWrapper {
  width: 48%;
  height: 20vh;
  border-radius: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload-logo {
  background-image: url('../img/upload-logo-image.png');
}

.add-team-members {
  background-image: url('../img/add-team-members.png');
}

.create-playlist {
  background-image: url('../img/create-playlist.png');
}

.connect-device {
  background-image: url('../img/connect-device.png');
}

.AttentionInnerCardContentWrapper {
  width: 48%;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1px 2px;
}
.AttentionInnerCardContentTitle {
  font-size: 15px;
  color: $color_5;
  font-weight: 600;
}
.caughtupText {
  color: $color_4;
}
.AttentionInnerCardContentBtnWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}
.AttentionInnerCardContentBtnText {
  font-size: 15px;
  color: $color_3;
  font-weight: 400;
  margin-right: 10px;
}
.AttentionInnerCardContentBtnArrowIcon {
  display: flex;
  height: 100px;
  width: 200px;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;

  // width: 8px;
  // height: 12px;
}
.rightarrowImg {
  width: 8px;
  height: 12px;
  margin-top: 7px;
}
.secondRow {
  width: 88vw;
  flex-direction: row;
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  // background-color: #000000;
}
.teamMember {
  width: 58% !important;
  min-height: 30vh !important;
  background-color: $background-color_1;
  overflow: hidden;
  border-radius: 20px;
  padding: 30px;
  margin-right: 2%;
}

.teamMemberPayment {
  width: 100% !important;
  min-height: 30vh !important;
  background-color: $background-color_1;
  overflow: hidden;
  border-radius: 20px;
  padding: 30px;
  margin-top: 2%;
}
.Announcements {
  width: 38% !important;
  background-color: $background-color_1;
  overflow: hidden;
  border-radius: 20px;
  padding: 30px;
}
.PaymentAnnouncements {
  width: 100%;
  min-height: 550px;
  background-color: $background-color_1;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 20px;
  padding: 35px;
  margin-top: 20px;
  margin-bottom: 120px;
}
.annoucementImg {
  border-radius: 10px;
}
.annoucementTitle {
  font-size: 16px;
  color: $color_5;
  font-weight: 500;
}
.annoucementDesc {
  font-size: 12px;
  color: $color_3;
  font-weight: 300;
}
.dottedAddTeamMemberWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dottedAddTeamMemberCircle {
  width: 97px;
  height: 97px;
  border: 2px dashed #000000;
  border-style: dashed;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
  span {
    font-size: 30px;
    color: $color_7;
    font-weight: 300;
    line-height: 30px;
  }
  div {
    font-size: 12px;
    color: $color_8;
    font-weight: 300;
  }
}
.dottedAddTeamMemberText {
  font-size: 13px;
  color: $color_8;
  font-weight: 300;
  text-align: center;
  line-height: 20px;
}
.dottedAddTeamMemberText2 {
  font-size: 12px;
  color: $color_8;
  font-weight: 300;
  text-align: center;
  line-height: 20px;
}
.menuWrapper {
  width: 86vw;
  margin-bottom: 10px;
  position: relative;
  // bottom: 2vh;
}
.menu {
  width: 85vw;
}
.selectItemDiv {
  border-bottom: 2px solid #000000 !important;
  width: 60%;
  margin: auto;
  align-self: center !important;
  position: absolute;
  height: 0.2vh;
  left: 0;
  right: 0;
  border-radius: 100px;
  bottom: -7px;
}
.selectItem {
  position: relative;
  border-bottom: 0px solid #000000 !important;
}
.unselectItem {
  border: 0px;
}
.uploadLogoWrapper {
  background-color: $background-color_3;
  width: fit-content;
}
.visaDot_icons {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #000000;
}
.card_Icon {
  width: 40px;
  height: 30px;
  object-fit: contain;
  object-position: center;
}
.buttonWrapper {
  padding-top: 20px;
}
.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
  margin-top: 20px;
}
.supportCard {
  display: flex;
  flex-direction: column;
  background-color: $color_9;
  border-radius: 20px;
  height: 190px;
  width: 190px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  text-align: center;
}
.cardImageSupport {
  // padding: 10px;
  height: 80px;
  width: 80px;
}
.help_textarea {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 2px solid black;
  padding: 10px;
  font-size: 14px;
  resize: none;
}
.help_textarea::placeholder {
  font-size: 12px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.dropdown_helper {
  background-color: #fff;
  width: 100%;
  border: solid 2px #000;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px 20px;
  transition: all 250ms;
}
.dropdown_helper h4 {
  padding-bottom: 20px;

  font-weight: 700;
}
.dropdown_helper li {
  padding: 10px 0px;
  transition: all 250ms;
}
.dropdown_helper li:hover {
  background-color: #fafafa;
}
#form-file-upload {
  height: 100px;
  width: 100%;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  // border: 3px dashed #000;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='7' ry='7' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 15' stroke-dashoffset='11' stroke-linecap='round'/%3e%3c/svg%3e");
  margin-top: 5px;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.helper_attention_content {
  background-color: #fafafa;
  transition: all 250ms;
}
// .helper_attention_content:hover {
//   background-color: #212b36;
//   color: white;
// }
.caughtUp_wrapper {
  display: flex;
  border-radius: 10px;
  background-color: #fff;
  padding: 25px 35px;
}
.caughtup_image {
  width: 113px;
  height: 88px;
  background-image: url(../img/sports.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 16px;
}
.caughtup_text h2 {
  font-size: 20px;
  color: #212b36;
  font-weight: 700;
}
.caughtup_text p {
  font-size: 16px;
  color: #637381;
  margin-top: 16px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #dadada #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 18px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
