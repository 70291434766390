:root {
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo,
    monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

summary {
  display: list-item;
}

blockquote,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
:after,
:before {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #a1a1aa;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  color: #a1a1aa;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #a1a1aa;
}

[role='button'],
button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

code,
kbd,
pre,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

* {
  scrollbar-color: auto;
  scrollbar-width: auto;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.aspect-w-16 {
  position: relative;
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
}

.aspect-w-16 > * {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect-w-16 {
  --tw-aspect-w: 16;
}

.aspect-h-9 {
  --tw-aspect-h: 9;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.bg-bg100 {
  background-color: rgba(255, 255, 255, 1);
}

.bg-bg100,
.bg-bg200 {
  --tw-bg-opacity: 1;
}

.bg-bg200 {
  background-color: rgba(242, 245, 250, 1);
}

.bg-bg800 {
  background-color: rgba(32, 31, 31, 1);
}

.bg-bg800,
.bg-bg900 {
  --tw-bg-opacity: 1;
}

.bg-bg900 {
  background-color: rgba(0, 0, 0, 1);
}

.bg-status300 {
  background-color: rgba(232, 81, 81, 1);
}

.bg-status300,
.bg-text500 {
  --tw-bg-opacity: 1;
}

.bg-text500 {
  background-color: rgba(98, 98, 98, 1);
}

.bg-text900 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 1);
}

.bg-transparent {
  background-color: transparent;
}

.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}

.bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.border-bg100 {
  border-color: rgba(255, 255, 255, 1);
}

.border-bg900 {
  border-color: rgba(0, 0, 0, 1);
}

.border-error900 {
  --tw-border-opacity: 1;
  border-color: rgba(232, 81, 81, 1);
}

.border-outline100 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, 1);
}

.border-outline200 {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 214, 1);
}

.border-outline300 {
  --tw-border-opacity: 1;
  border-color: rgba(150, 159, 161, 1);
}

.border-outline900 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, 1);
}

.rounded {
  border-radius: 4px;
}

.rounded-lg {
  border-radius: 8px;
}

.rounded-2xl {
  border-radius: 16px;
}

.rounded-3xl {
  border-radius: 32px;
}

.rounded-4xl {
  border-radius: 48px;
}

.rounded-large {
  border-radius: 24px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-4xl {
  border-top-left-radius: 48px;
  border-top-right-radius: 48px;
}

.rounded-t-large {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.last\:rounded-b-lg:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.box-border {
  box-sizing: border-box;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: 8px;
}

.h-2 {
  height: 16px;
}

.h-3 {
  height: 24px;
}

.h-4 {
  height: 32px;
}

.h-5 {
  height: 40px;
}

.h-6 {
  height: 48px;
}

.h-9 {
  height: 72px;
}

.h-11 {
  height: 88px;
}

.h-12 {
  height: 96px;
}

.h-auto {
  height: auto;
}

.h-1\/2 {
  height: 50%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-contact {
  background-color: black;
}
.text-3xl {
  font-size: 28px;
}

.text-8xl {
  font-size: 64px;
}

.m-auto {
  margin: auto;
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mx-6 {
  margin-left: 48px;
  margin-right: 48px;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mr-1 {
  margin-right: 8px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mr-3 {
  margin-right: 24px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mr-4 {
  margin-right: 32px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-10 {
  margin-top: 80px;
}

.ml-14 {
  margin-left: 112px;
}

.mt-20 {
  margin-top: 160px;
}

.mt-4px {
  margin-top: 4px;
}

.mb-4px {
  margin-bottom: 4px;
}

.mt-12px {
  margin-top: 12px;
}

.mr-12px {
  margin-right: 12px;
}

.mb-12px {
  margin-bottom: 12px;
}

.-mt-2 {
  margin-top: -16px;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-popup {
  max-width: 224px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-popup {
  min-width: 148px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-30 {
  opacity: 0.3;
}

.focus\:outline-none:focus,
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.p-6 {
  padding: 48px;
}

.p-7 {
  padding: 56px;
}

.p-12px {
  padding: 12px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.px-2 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.px-3 {
  padding-left: 24px;
  padding-right: 24px;
}
.left-50 {
  position: relative;
  right: 50px;
}

.lib-1 {
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  clippath: inset(50%);
  height: 1px;
  margin: 0px -1px -1px 0px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.lib-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.lib-3 {
  box-sizing: border-box;
  padding-top: 0px;
  margin-top: 0px;
}

.lib-4 {
  height: 100%;
  outline: none;
  overflow-y: auto;
  position: relative;
}
.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.px-4 {
  padding-left: 32px;
  padding-right: 32px;
}

.px-5 {
  padding-left: 40px;
  padding-right: 40px;
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.px-6 {
  padding-left: 48px;
  padding-right: 48px;
}

.px-11 {
  padding-left: 88px;
  padding-right: 88px;
}

.py-4px {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-12px {
  padding-top: 12px;
  padding-bottom: 12px;
}

.px-12px {
  padding-left: 12px;
  padding-right: 12px;
}

.pt-2 {
  padding-top: 16px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pl-2 {
  padding-left: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pt-5 {
  padding-top: 40px;
}

.pb-6 {
  padding-bottom: 48px;
}

.pt-12 {
  padding-top: 96px;
}

.pt-4px {
  padding-top: 4px;
}

.pb-4px {
  padding-bottom: 4px;
}

.pointer-events-none {
  pointer-events: none;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: 8px;
}

.right-1 {
  right: 8px;
}

.bottom-1 {
  bottom: 8px;
}

.left-1 {
  left: 8px;
}

.right-2 {
  right: 16px;
}

.top-3 {
  top: 24px;
}

.right-3 {
  right: 24px;
}

.bottom-3 {
  bottom: 24px;
}

.top-6 {
  top: 48px;
}

.bottom-6 {
  bottom: 48px;
}

.top-12 {
  top: 96px;
}

.left-16 {
  left: 128px;
}

.-top-1 {
  top: -8px;
}

.-right-1 {
  right: -8px;
}

.-bottom-1 {
  bottom: -8px;
}

.-left-1 {
  left: -8px;
}

.-bottom-2 {
  bottom: -16px;
}

.-left-2 {
  left: -16px;
}

.-top-12px {
  top: -12px;
}

.-bottom-12px {
  bottom: -12px;
}

.top-1\/2 {
  top: 50%;
}

.bottom-1\/2 {
  bottom: 50%;
}

.left-1\/2 {
  left: 50%;
}

.resize {
  resize: both;
}

* {
  --tw-shadow: 0 0 transparent;
}

.shadow-shadow100 {
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
}

.shadow-shadow100,
.shadow-shadow200 {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.shadow-shadow200 {
  --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
}

.shadow-shadow500 {
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.48);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 transparent;
  --tw-ring-shadow: 0 0 transparent;
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.ring-2,
.ring-4 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
}

.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}

.ring-bg900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.fill-current {
  fill: currentColor;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-accent900 {
  color: rgba(77, 199, 191, var(--tw-text-opacity));
}

.text-accent900,
.text-bg700 {
  --tw-text-opacity: 1;
}

.text-bg700 {
  color: rgba(77, 75, 75, var(--tw-text-opacity));
}

.text-bg800 {
  color: rgba(32, 31, 31, var(--tw-text-opacity));
}

.text-bg800,
.text-status100 {
  --tw-text-opacity: 1;
}

.text-status100 {
  color: rgba(77, 199, 191, var(--tw-text-opacity));
}

.text-status200 {
  --tw-text-opacity: 1;
  color: rgba(234, 184, 8, var(--tw-text-opacity));
}

.text-status300 {
  --tw-text-opacity: 1;
  color: rgba(232, 81, 81, var(--tw-text-opacity));
}

.text-text100 {
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-text100,
.text-text200 {
  --tw-text-opacity: 1;
}

.text-text200 {
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.text-text300 {
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.text-text300,
.text-text500 {
  --tw-text-opacity: 1;
}

.text-text500 {
  color: rgba(98, 98, 98, var(--tw-text-opacity));
}

.text-text800 {
  color: rgba(62, 62, 62, var(--tw-text-opacity));
}

.text-text800,
.text-text900 {
  --tw-text-opacity: 1;
}

.text-text900 {
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.hover\:text-text500:hover {
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
}

.hover\:text-text900:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.capitalize {
  text-transform: capitalize;
}
.contact-text {
  color: #979fa1;
  font-size: 20px;
  font-weight: 600;
}
.choose-text {
  color: #000000;
  font-size: 20px;
  font-weight: 400;
}
.iconChoose {
  margin-top: 5px;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: 8px;
}

.w-2 {
  width: 16px;
}

.w-3 {
  width: 24px;
}

.w-4 {
  width: 32px;
}

.w-5 {
  width: 40px;
}

.w-6 {
  width: 48px;
}

.w-10 {
  width: 80px;
}

.w-11 {
  width: 88px;
}

.w-13 {
  width: 104px;
}

.w-14 {
  width: 112px;
}

.w-15 {
  width: 120px;
}

.w-16 {
  width: 128px;
}

.w-22 {
  width: 176px;
}

.w-24 {
  width: 192px;
}

.w-25 {
  width: 200px;
}

.w-4px {
  width: 4px;
}

.w-sidebar {
  width: 276px;
}

.w-1\/2 {
  width: 50%;
}

.w-full {
  width: 100%;
}

.w-max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-modal {
  z-index: 1000;
}

.z-confirm {
  z-index: 10000;
}

.z-notification {
  z-index: 100000;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-6 {
  gap: 48px;
}

.gap-9 {
  gap: 72px;
}

.gap-4px {
  gap: 4px;
}

.gap-12px {
  gap: 12px;
}

.gap-x-3 {
  -moz-column-gap: 24px;
  column-gap: 24px;
}

.gap-x-5 {
  -moz-column-gap: 40px;
  column-gap: 40px;
}

.gap-x-12px {
  -moz-column-gap: 12px;
  column-gap: 12px;
}

.gap-y-2 {
  row-gap: 16px;
}

.gap-y-3 {
  row-gap: 24px;
}

.gap-y-5 {
  row-gap: 40px;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
  min-width: 48%;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-start-3 {
  grid-column-start: 3;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.origin-center {
  transform-origin: center;
}

.origin-right {
  transform-origin: right;
}

.origin-bottom {
  transform-origin: bottom;
}

.rotate-0 {
  --tw-rotate: 0deg;
}

.rotate-45 {
  --tw-rotate: 45deg;
}

.rotate-180 {
  --tw-rotate: 180deg;
}

.-rotate-45 {
  --tw-rotate: -45deg;
}

.translate-x-full {
  --tw-translate-x: 100%;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.duration-300 {
  transition-duration: 0.3s;
}

@-webkit-keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@-webkit-keyframes ping {
  75%,
  to {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%,
  to {
    transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@-webkit-keyframes bounce {
  0%,
  to {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounce {
  0%,
  to {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.animate-fade-in {
  -webkit-animation: fade-in 0.3s ease;
  animation: fade-in 0.3s ease;
}

.scrollbar {
  --scrollbar-track: initial;
  --scrollbar-thumb: initial;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  overflow: overlay;
}

.scrollbar.overflow-y-hidden {
  overflow-y: hidden;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
}

.scrollbar {
  scrollbar-width: auto;
}

.scrollbar::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.scrollbar-thin {
  --scrollbar-track: initial;
  --scrollbar-thumb: initial;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
  overflow: overlay;
}

.scrollbar-thin.overflow-y-hidden {
  overflow-y: hidden;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
}

.scrollbar-thin {
  scrollbar-width: thin;
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scrollbar-thumb-rounded-lg::-webkit-scrollbar-thumb {
  border-radius: 8px;
}

.scrollbar-thumb-bg700 {
  --scrollbar-thumb: #4d4b4b;
}

.scrollbar-track-transparent {
  --scrollbar-track: transparent;
}

.v-bs-btn100 {
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
}

.v-bs-btn200 {
  font-size: 15px;
}

.v-bs-btn200,
.v-bs-btn300 {
  font-family: Mulish, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
}

.v-bs-btn300 {
  font-size: 18px;
}

.v-bs-h200 {
  font-size: 16px;
  line-height: 1.35;
}

.v-bs-h200,
.v-bs-h300 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-h300 {
  font-size: 17px;
  line-height: 1.2;
}

.v-bs-h400 {
  font-size: 22px;
  line-height: 1.28;
}

.v-bs-h400,
.v-bs-h500 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-h500 {
  font-size: 28px;
  line-height: 1.16;
}

.v-bs-h600 {
  font-family: Karla, Arial, sans-serif;
  line-height: 1.28;
}

.v-bs-h600,
.v-bs-h900 {
  font-size: 32px;
  font-weight: 700;
  text-transform: none;
}

.v-bs-h900 {
  font-family: Merriweather, Arial, sans-serif;
  line-height: 1.34;
}

.v-bs-h950 {
  font-family: Karla, Arial, sans-serif;
  font-size: 40px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.28;
}

.v-bs-initia100 {
  font-size: 14px;
}

.v-bs-initia100,
.v-bs-initial200 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  line-height: 1;
}

.v-bs-initial200 {
  font-size: 18px;
}

.v-bs-initial300 {
  font-size: 28px;
}

.v-bs-initial300,
.v-bs-initial400 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  line-height: 1;
}

.v-bs-initial400 {
  font-size: 40px;
}

.v-bs-input-error100 {
  font-size: 12px;
  line-height: 1.2;
}

.v-bs-input-error100,
.v-bs-input-label200 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-input-label200 {
  font-size: 14px;
  line-height: 1.44;
}

.v-bs-input-label300 {
  font-size: 16px;
  line-height: 1.48;
}

.v-bs-input-label300,
.v-bs-input-ph100 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-input-ph100 {
  font-size: 12px;
  line-height: 1.2;
}

.v-bs-input-ph200 {
  font-size: 18px;
  line-height: 1.32;
}

.v-bs-input-ph200,
.v-bs-input-value100 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-input-value100 {
  font-size: 16px;
  line-height: 1.28;
}

.v-bs-input-value200 {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.32;
}

.v-bs-input-value200,
.v-bs-label100 {
  font-family: Karla, Arial, sans-serif;
  text-transform: none;
}

.v-bs-label100 {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.48;
}

.v-bs-label200 {
  font-size: 12px;
  line-height: 1.2;
}

.v-bs-label200,
.v-bs-label300 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-label300 {
  font-size: 14px;
  line-height: 1.44;
}

.v-bs-label400 {
  font-size: 15px;
}

.v-bs-label400,
.v-bs-label500 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}

.v-bs-label500 {
  font-size: 18px;
}

.v-bs-list100 {
  font-size: 14px;
  line-height: 1.44;
}

.v-bs-list100,
.v-bs-list200 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-list200 {
  font-size: 16px;
  line-height: 1.14;
}

.v-bs-p100 {
  font-size: 14px;
}

.v-bs-p100,
.v-bs-p200 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 400;
  text-transform: none;
  line-height: 1.28;
}

.v-bs-p200 {
  font-size: 16px;
}

.v-bs-p250 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.28;
}

.v-bs-p250,
.v-bs-p300 {
  font-family: Karla, Arial, sans-serif;
  text-transform: none;
}

.v-bs-p300 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.32;
}

.v-bs-table-head100 {
  font-size: 13px;
  line-height: 1.38;
}

.v-bs-table-head100,
.v-bs-table-value100 {
  font-family: Karla, Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
}

.v-bs-table-value100 {
  font-size: 14px;
  line-height: 1.29;
}

.transform-3d,
.transform-preserve-3d {
  transform-style: preserve-3d;
}

.transform-3d {
  --tw-translate-z: 0;
  transform: translateZ(var(--tw-translate-z)) translateX(var(--tw-translate-x))
    translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
    skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
    scaleY(var(--tw-scale-y));
}

.-translate-z-1px {
  --tw-translate-z: -1px;
}

.drop-shadow300 {
  filter: drop-shadow(0 8px 24px rgba(0, 0, 0, 0.16));
}

.drop-shadow100 {
  filter: drop-shadow(0 12px 32px rgba(0, 0, 0, 0.04));
}

.drop-shadow200 {
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.12));
}

body,
html {
  height: 100%;
  margin: 0;
}

@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }
}

@media (min-width: 1280px) {
  .xl\:col-start-2 {
    grid-column-start: 2;
  }
}

.default-layout-container {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, 1);
  border-radius: 48px;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  padding: 24px;
  width: 89.5vw;
  overflow-x: hidden;
}

.default-layout-container > * {
  max-height: 100%;
}

.default-layout-container.default-layout-ht {
  height: 100%;
  position: fixed;
}

.main-nav-lt-items {
  position: relative;
}

.main-nav-lt-items.main-nav-lt-item-active:before {
  content: '';
  top: -36px;
  right: -32px;
  width: 92px;
  height: 120px;
  position: absolute;
  z-index: -1;
  background-image: url(../img/active-nav-item.svg);
}

.channeld-d-logo {
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.channeld-d-logo img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.lt-item-icon-box {
  border-radius: 8px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
}

.lt-item-icon-box:hover {
  cursor: pointer;
}

.lt-item-icon-box:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.lt-item-icon-box.btn-layout-border {
  border-radius: 9999px;
}

.lt-item-icon-box.index-StyledButton_isLoading__2RbHW {
  position: relative;
}

.lt-item-icon-box.box-shadow-focus:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(128, 187, 251, var(--tw-ring-opacity));
}

.lt-item-icon-box.lt-item-box-sizing {
  padding-left: 0;
  padding-right: 0;
  width: 48px;
}

.lt-item-icon-box.lt-item-box-bg {
  background-color: transparent;
}

.svg-icon-24 {
  width: 24px;
  height: 24px;
}

.lt-item-icon-box.lt-item-box-bg:hover {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.lt-item-icon-box.index-StyledButton_variant-link__1TBC0 {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
}

.lt-item-icon-box.index-StyledButton_variant-link__1TBC0:hover {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.lt-item-icon-box.index-StyledButton_variant-link__1TBC0:disabled,
.lt-item-icon-box.index-StyledButton_variant-link__1TBC0:disabled:hover {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.lt-item-icon-box.btn-layout-style {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 1);
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.lt-item-icon-box.btn-layout-style:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 75, 75, 1);
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.lt-item-icon-box.btn-layout-style:focus:not(:disabled),
.lt-item-icon-box.btn-layout-style:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 31, 31, 1);
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.lt-item-icon-box.index-StyledButton_variant-ghost__1dOzQ {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, 1);
  border-width: 2px;
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.lt-item-icon-box.index-StyledButton_variant-ghost__1dOzQ:disabled {
  --tw-border-opacity: 1;
  border-color: rgba(98, 98, 98, 1);
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.lt-item-icon-box.index-StyledButton_variant-ghost__1dOzQ:focus:not(:disabled),
.lt-item-icon-box.index-StyledButton_variant-ghost__1dOzQ:hover:not(:disabled) {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 214, 1);
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.lt-item-icon-box.index-StyledButton_variant-dashed__3ryYd {
  --tw-border-opacity: 1;
  border-radius: 16px;
  border: 2px dashed rgba(150, 159, 161, 1);
  box-sizing: border-box;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
}

.lt-item-icon-box.index-StyledButton_variant-dashed__3ryYd:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.lt-item-icon-box.edit-practice-btn {
  padding-left: 16px;
  padding-right: 16px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  height: 36px;
}

.lt-item-icon-box.index-StyledButton_size-large__3h4Sf {
  height: 56px;
  padding-left: 32px;
  padding-right: 32px;
}

.index-StyledSvg_cls1__3YcLt {
  /*!*/
}

.index-StyledSvg_cls2__nHIIG {
  display: inline-flex;
  flex-shrink: 0;
  fill: currentColor;
}

.user-menu-StyledUserMenu_cls1__3rd7Z {
  /*!*/
}

.user-menu-StyledUserMenu_cls2__2HmiN {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  position: absolute;
  bottom: -16px;
  left: -16px;
  --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  animation: user-menu-StyledUserMenu_fade-in__343K5 0.3s ease;
  width: 240px;
  height: 213px;
  z-index: 1000;
}

.practive-box-holder {
  background-position: 50%;
  background-size: cover;
}

.practive-box-holder.notification-p-border {
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, 1);
  border-width: 1px;
}

.notifition-earlier-wrap {
  width: 100%;
  position: sticky;
  top: 0px;
  margin-top: 0px;
}

.practive-box-holder.practive-box-holder-border {
  border-radius: 16px;
}

.practive-box-holder.practice-box-holder-border {
  border-radius: 9999px;
}

.practive-box-holder.index-StyledImageThumb_size-100__1R1fv {
  height: 32px;
  width: 32px;
}

.practive-box-holder.index-StyledImageThumb_size-200__-Kwu9 {
  height: 40px;
  width: 40px;
}

.practive-box-holder.notification-p-logo {
  height: 48px;
  width: 48px;
}

.practive-box-holder.practice-box-other-p {
  height: 80px;
  width: 80px;
}

.practive-box-holder.practive-box-holder-size {
  height: 88px;
  width: 88px;
}

.practive-box-holder.index-StyledImageThumb_size-500__3AeNI {
  height: 104px;
  width: 104px;
}

.practive-box-holder.practice-box-holder-128 {
  height: 128px;
  width: 128px;
}

.separator-hr {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 214, 214, 1);
}

.separator-hr.separator-hr {
  margin-top: 24px;
  margin-bottom: 24px;
  height: 1px;
  width: 100%;
}

.Separator_cls2__89WVX.Separator_orientation-vertical__16IVi {
  margin-left: 24px;
  margin-right: 24px;
  width: 1px;
}

.Separator_cls2__89WVX.Separator_noMargin__3zi12 {
  margin: 0;
}

.index-StyledMenuItem_cls1__2Zavz {
  /*!*/
}

.index-StyledMenuItem_cls2__34lgK {
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  width: 100%;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}

.index-StyledMenuItem_cls2__34lgK.index-StyledMenuItem_active__2o0ev {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, 1);
}

.index-StyledMenuItem_cls2__34lgK.index-StyledMenuItem_isFocused__398Rx,
.index-StyledMenuItem_cls2__34lgK:hover {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.StyledMenu_cls1__mnjAB {
  /*!*/
}

.StyledMenu_cls2__1JOU8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  gap: 8px;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-translate-x: -50%;
  border-radius: 16px;
  max-width: 224px;
  min-width: 148px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 50;
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.12));
  top: calc(100% + 12px);
}

.StyledMenu_cls2__1JOU8,
.StyledMenu_cls2__1JOU8:before {
  position: absolute;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.StyledMenu_cls2__1JOU8:before {
  content: '';
  height: 16px;
  top: -8px;
  width: 16px;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-rotate: 45deg;
}

.notification-box {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 16px;
  display: flex;
  align-items: stretch;
  padding: 16px;
  position: relative;
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.48);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  gap: 12px;
  -webkit-animation: notification-StyledNotification_fade-in__t7UTm 0.3s ease;
  animation: notification-StyledNotification_fade-in__t7UTm 0.3s ease;
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  line-height: 1.32;
}

.notification-box:before {
  content: '';
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  width: 4px;
  border-radius: 26px;
}

.notification-box.notification-StyledNotification_status-warning__16y98:before {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 184, 8, 1);
}

.notification-box.notification-StyledNotification_status-error__5qAsm:before {
  --tw-bg-opacity: 1;
  background-color: rgba(232, 81, 81, 1);
}

.notification-box.notification-StyledNotification_status-success__207QU:before {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 199, 191, 1);
}

.team-field-group {
  flex-grow: 1;
  position: relative;
}
.newColor {
  color: #212b36;
}

.index-StyledLabel_cls2__Mzjwg {
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}

.index-StyledLabel_cls2__Mzjwg.index-StyledLabel_disabled__2QNXs {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.index-StyledLabel_cls2__Mzjwg.index-StyledLabel_required__nAoU6 {
  position: relative;
}

.index-StyledLabel_cls2__Mzjwg.index-StyledLabel_required__nAoU6:after {
  content: '*';
  margin-left: 4px;
}

.team-field-control {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, 1);
  border-radius: 8px;
  border-width: 2px;
  padding: 8px 16px;
  width: 100%;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.3s;
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
  height: 48px;
}

.team-field-control::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
}

.team-field-control:-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
}

.team-field-control::placeholder {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
}

.team-field-control:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.team-field-control:disabled,
.team-field-control:focus {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 214, 1);
}

.team-field-control:disabled {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.team-field-control.index-StyledInput_isInvalid__1biLw {
  --tw-border-opacity: 1;
  border-color: rgba(232, 81, 81, 1);
}

.team-field-control.index-StyledInput_size-large__1bVox {
  height: 56px;
}

.team-field-control.index-StyledInput_isSearch__rMkwz {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, 1);
  border-radius: 9999px;
}

.FieldCaption_cls1__3v4F6 {
  /*!*/
}

.FieldCaption_cls2__2ATLc {
  margin-top: 8px;
  text-align: right;
  font-family: Karla, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}

.FieldCaption_cls2__2ATLc.FieldCaption_isError__2M151 {
  --tw-text-opacity: 1;
  color: rgba(232, 81, 81, var(--tw-text-opacity));
}

.notifications-popup-wrap {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  width: 480px;
  min-height: 340px;
  height: 80%;
  max-height: 968px;
}
.notifications-popup-position {
  position: absolute;
  z-index: 100000;
  left: auto;
  top: 96px;
  max-height: 588px;
  right: 0;
  height: 1000px;
}

.notifications-popup-inner {
  height: 100%;
  outline: none;
  overflow-y: auto;
  position: relative;
}

.notifications-popup-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.notifications-popup-content-style {
  box-sizing: border-box;
  padding-top: 38px;
  margin-top: 0px;
}

.tooltip-StyledTooltip_cls1___eSz4 {
  /*!*/
}

.tooltip-StyledTooltip_cls2__2nrIw {
  --tooltip-offset: 8px;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 16px;
  max-width: 224px;
  padding: 12px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  --tw-scale-y: 1;
  --tw-rotate: 0deg;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  line-height: 1.28;
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.12));
}

.tooltip-StyledTooltip_cls2__2nrIw,
.tooltip-StyledTooltip_cls2__2nrIw:before {
  display: inline-block;
  position: absolute;
  z-index: 10;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tooltip-StyledTooltip_cls2__2nrIw:before {
  --tw-scale-y: 1;
  content: '';
  // background-image: url(../img/tooltip-arrow.png);
  // background-repeat: no-repeat;
  width: 8px;
  height: 20px;
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-bottom__2MNYf,
.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-bottom__2MNYf:before,
.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-top__10PND,
.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-top__10PND:before {
  left: 50%;
  --tw-translate-x: -50%;
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-left__1ne8U,
.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-left__1ne8U:before,
.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-right__DKWZk,
.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-right__DKWZk:before {
  top: 50%;
  --tw-translate-y: -50%;
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-right__DKWZk {
  left: calc(100% + var(--tooltip-offset));
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-right__DKWZk:before {
  left: -8px;
  --tw-rotate: 180deg;
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-left__1ne8U {
  right: calc(100% + var(--tooltip-offset));
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-left__1ne8U:before {
  right: -8px;
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-top__10PND {
  bottom: calc(100% + var(--tooltip-offset));
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-top__10PND:before {
  bottom: -12px;
  --tw-rotate: 90deg;
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-bottom__2MNYf {
  top: calc(100% + var(--tooltip-offset));
}

.tooltip-StyledTooltip_cls2__2nrIw.tooltip-StyledTooltip_placement-bottom__2MNYf:before {
  top: -12px;
  --tw-rotate: -90deg;
}

.confirm-modal-StyledConfirm_cls1__3hxBv {
  /*!*/
}

.confirm-modal-StyledConfirm_cls2__2TkMw {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 48px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 200px;
  gap: 16px;
  width: 380px;
}

.display-hidden {
  display: none !important;
}

.upload-logo-w {
  width: 400px !important;
}

.card-1 {
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
}

.coupon-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}

.coupon-1Wrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.coupon-1 {
  position: relative;
}

.device-img {
  width: 130px !important;
  height: auto !important;
}

.wrapper-ann {
  pointer-events: auto;
  position: relative;
  height: calc(100vh - 24px - 40px - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}
.announcements-wrap-heigh {
  pointer-events: auto;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.announcements-content {
  pointer-events: auto;
  position: relative;
  height: calc(100vh - 24px - 40px - 40px);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
}

.device-img-2 {
  width: 120px !important;
  height: auto !important;
}
.practice-logo-display {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, 1);
  border-radius: 16px;
  border-width: 1px;
  box-sizing: border-box;
  flex-grow: 0;
  padding: 8px;
}

.practice-logo-display.practice-logo-StyledLogo_withImage__1sycn {
  background: linear-gradient(0deg, #f2fcff, #f2fcff),
    linear-gradient(0deg, #f2fff5, #f2fff5),
    linear-gradient(0deg, #f5fff2, #f5fff2),
    linear-gradient(0deg, #fff2f2, #fff2f2), #f2f3ff;
}

.hint-StyledHint_cls1__2u_HS {
  /*!*/
}

.hint-StyledHint_cls2__3AcUx {
  position: relative;
}

.hint-StyledHint_cls2__3AcUx.hint-StyledHint_hintType-error__1TuRN {
  --tw-text-opacity: 1;
  color: rgba(232, 81, 81, var(--tw-text-opacity));
}

.hint-StyledHint_cls2__3AcUx.hint-StyledHint_hintType-warning__2KUDN {
  --tw-text-opacity: 1;
  color: rgba(234, 184, 8, var(--tw-text-opacity));
}

.practice-col-style {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, 1);
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, 1);
  border-radius: 24px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.setting-col-full {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(245, 245, 245, 1);
  border-radius: 24px;
  border-width: 1px;
  padding: 40px;
  box-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
}

.index-StyledDropArea_cls2__2k1AR {
  --tw-border-opacity: 1;
  border-radius: 8px;
  border: 2px dashed rgba(0, 0, 0, 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 220px;
}

.index-StyledDropArea_cls2__2k1AR:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, 1);
}

.index-StyledDropArea_cls2__2k1AR:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.team-member-list-item {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
  max-width: 88px;
}

.stars-counter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}

.stars-counter-box.stars-counter-box-bg {
  height: 32px;
  width: 32px;
  background-image: url(../img/counter-star.png);
}

.stars-counter-box.counter-StyledCount_variant-round__b-CQR {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 9999px;
  height: 24px;
  width: 24px;
}

.stars-counter-box.counter-StyledCount_textSize-small__3UwQF {
  font-family: Karla, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}

.additional-photo-list-item {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-family: Karla, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
  max-width: 88px;
}

.team-btn-box:not(:disabled):hover {
  --tw-border-opacity: 1;
  border-color: rgba(150, 159, 161, 1);
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(62, 62, 62, var(--tw-text-opacity));
}
.team-btn-box.team-btn-box-border {
  border-radius: 9999px;
}
.team-btn-box {
  --tw-border-opacity: 1;
  border-radius: 8px;
  border: 2px dashed rgba(0, 0, 0, 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
}
.team-btn-content {
  margin-top: 8px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
  max-width: 88px;
}

.lt-item-icon-box.close-popup-practice {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, 1);
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: 9999px;
  padding-left: 0;
  padding-right: 0;
  width: 48px;
}
.team-popup-wd {
  width: 700px;
}
.team-thubm-size {
  height: 67px;
  width: 120px;
}

.practice-logo-StyledLogo_cls1__3VZmm {
  /*!*/
}

.practice-logo-StyledLogo_cls2__3B-UL {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-radius: 16px;
  border-width: 1px;
  box-sizing: border-box;
  flex-grow: 0;
  padding: 8px;
}

.practice-logo-StyledLogo_cls2__3B-UL.practice-logo-StyledLogo_withImage__1sycn {
  background: linear-gradient(0deg, #f2fcff, #f2fcff),
    linear-gradient(0deg, #f2fff5, #f2fff5),
    linear-gradient(0deg, #f5fff2, #f5fff2),
    linear-gradient(0deg, #fff2f2, #fff2f2), #f2f3ff;
}

.hint-StyledHint_cls1__2u_HS {
  /*!*/
}

.hint-StyledHint_cls2__3AcUx {
  position: relative;
}

.hint-StyledHint_cls2__3AcUx.hint-StyledHint_hintType-error__1TuRN {
  --tw-text-opacity: 1;
  color: rgba(232, 81, 81, var(--tw-text-opacity));
}

.hint-StyledHint_cls2__3AcUx.hint-StyledHint_hintType-warning__2KUDN {
  --tw-text-opacity: 1;
  color: rgba(234, 184, 8, var(--tw-text-opacity));
}

.edit-btn-setting {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
}

.practice-settings-section-title {
  display: grid;
  align-items: center;
  justify-content: flex-start;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-family: Karla, Arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.28;
}

.index-RadioCheck_cls1__3KCy7 {
  /*!*/
}

.index-RadioCheck_cls2__2mNt6 {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 2px;
  height: 16px;
  width: 16px;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
  padding: 2px;
}

.index-RadioCheck_cls2__2mNt6,
.index-RadioCheck_cls2__2mNt6:before {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.index-RadioCheck_cls2__2mNt6:before {
  content: '';
}

.index-RadioCheck_cls2__2mNt6.index-RadioCheck_isChecked__2fuZL:before {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: block;
  height: 100%;
  width: 100%;
}

.index-RadioButton_cls1__25du7 {
  /*!*/
}

.index-RadioButton_cls2__2BIPY {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.index-RadioButton_cls2__2BIPY:hover {
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
}

.index-RadioButton_cls2__2BIPY:hover .index-RadioCheck_cls1__3KCy7 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 98, 98, var(--tw-border-opacity));
}

.index-RadioButton_cls2__2BIPY.index-RadioButton_disabled__1f5Pe {
  cursor: not-allowed;
  pointer-events: none;
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.index-RadioButton_cls2__2BIPY.index-RadioButton_disabled__1f5Pe
  .index-RadioCheck_cls1__3KCy7 {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 214, var(--tw-border-opacity));
}

.index-RadioButton_cls2__2BIPY.index-RadioButton_disabled__1f5Pe
  .index-RadioCheck_cls1__3KCy7:before {
  --tw-bg-opacity: 1;
  background-color: rgba(214, 214, 214, var(--tw-bg-opacity));
}

.index-CheckboxWrapper_cls1__lX2_K {
  /*!*/
}

.index-CheckboxWrapper_cls2__7xv4H {
  position: relative;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}

.index-CheckboxIconWrapper_cls1__2hlg4 {
  /*!*/
}

.index-CheckboxIconWrapper_cls2__mpdXV {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-radius: 6px;
  border-width: 2px;
  box-sizing: border-box;
  height: 24px;
  margin-right: 8px;
  position: relative;
  width: 24px;
}

.index-CheckboxIconWrapper_cls2__mpdXV > * {
  opacity: 0;
  position: absolute;
  top: -2px;
  left: -2px;
}

.index-CheckboxText_cls1__2MgZJ {
  /*!*/
}

.index-CheckboxText_cls2__18gYK {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}

.index-CheckboxLabel_cls1__26N4G {
  /*!*/
}

.index-CheckboxLabel_cls2__1mHQh {
  cursor: pointer;
  display: flex;
  align-items: center;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.index-CheckboxLabel_cls2__1mHQh:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.index-CheckboxLabel_cls2__1mHQh:hover .index-CheckboxIconWrapper_cls1__2hlg4 {
  --tw-border-opacity: 1;
  border-color: rgba(98, 98, 98, var(--tw-border-opacity));
}

.index-CheckboxLabel_cls2__1mHQh:hover .index-CheckboxText_cls1__2MgZJ {
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
}

.index-CheckboxInput_cls1__1eh11 {
  /*!*/
}

.index-CheckboxInput_cls2__37GOC {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.index-CheckboxInput_cls2__37GOC:active
  + .index-CheckboxIconWrapper_cls1__2hlg4 {
  --tw-border-opacity: 1;
  border-color: rgba(150, 159, 161, var(--tw-border-opacity));
}

.index-CheckboxInput_cls2__37GOC:checked
  + .index-CheckboxIconWrapper_cls1__2hlg4
  > * {
  opacity: 1;
}

.index-CheckboxInput_cls2__37GOC:disabled
  + .index-CheckboxIconWrapper_cls1__2hlg4 {
  --tw-border-opacity: 1;
  border-color: rgba(214, 214, 214, var(--tw-border-opacity));
}

.index-CheckboxInput_cls2__37GOC:disabled ~ .index-CheckboxText_cls1__2MgZJ {
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.default-layout-ContentContainer_cls1__kZRCK {
  /*!*/
}

.default-layout-ContentContainer_cls2__1XHKN {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
  border-radius: 48px;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  padding: 24px;
}

.default-layout-ContentContainer_cls2__1XHKN > * {
  max-height: 100%;
}

.default-layout-ContentContainer_cls2__1XHKN.default-layout-ContentContainer_fullHeight__MbLAV {
  height: 100%;
}

.main-navigation-StyledNavigationItem_cls1__L74ex {
  /*!*/
}

.main-navigation-StyledNavigationItem_cls2__2pkDm {
  position: relative;
}

.main-navigation-StyledNavigationItem_cls2__2pkDm.main-navigation-StyledNavigationItem_isActive__cyq-F:before {
  content: '';
  top: -36px;
  right: -32px;
  width: 92px;
  height: 120px;
  position: absolute;
  z-index: -1;
  background-image: url(../img/active-nav-item.svg);
}

.user-menu-StyledUserMenu_cls1__3rd7Z {
  /*!*/
}

.user-menu-StyledUserMenu_cls2__2HmiN {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 16px;
  position: absolute;
  bottom: -16px;
  left: -16px;
  --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  animation: user-menu-StyledUserMenu_fade-in__343K5 0.3s ease;
  width: 240px;
  height: 213px;
  z-index: 1000;
}

.index-StyledMenuItem_cls1__2Zavz {
  /*!*/
}

.index-StyledMenuItem_cls2__34lgK {
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  width: 100%;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}

.index-StyledMenuItem_cls2__34lgK.index-StyledMenuItem_active__2o0ev {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
}

.index-StyledMenuItem_cls2__34lgK.index-StyledMenuItem_isFocused__398Rx,
.index-StyledMenuItem_cls2__34lgK:hover {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.StyledMenu_cls1__mnjAB {
  /*!*/
}

.StyledMenu_cls2__1JOU8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  gap: 8px;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-translate-x: -50%;
  border-radius: 16px;
  max-width: 224px;
  min-width: 148px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 50;
  filter: drop-shadow(0 4px 24px rgba(0, 0, 0, 0.12));
  top: calc(100% + 12px);
}

.StyledMenu_cls2__1JOU8,
.StyledMenu_cls2__1JOU8:before {
  position: absolute;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.StyledMenu_cls2__1JOU8:before {
  content: '';
  height: 16px;
  top: -8px;
  width: 16px;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-rotate: 45deg;
}

.notifications-popup-StyledPopup_cls1__1-LT8 {
  /*!*/
}

.notifications-popup-StyledPopup_cls2__rvBPQ {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  width: 480px;
  min-height: 340px;
  height: 80%;
  max-height: 968px;
}

.setting-taps-holder {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.28;
  height: 32px;
}

.setting-taps-holder.setting-taps-active {
  border-radius: 9999px;
  box-sizing: border-box;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 transparent);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.setting-taps-holder.setting-taps-active:hover {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(150, 159, 161, var(--tw-ring-opacity));
}

.setting-taps-holder:focus,
.setting-taps-holder:hover {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.setting-taps-holder:hover {
  --tw-text-opacity: 1;
  color: rgba(150, 159, 161, var(--tw-text-opacity));
}

.index-StyledCard_cls1__2xx2S {
  /*!*/
}

.index-StyledCard_cls2__cnQbX {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-radius: 24px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.credit-card-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  gap: 24px;
  width: 200px;
  min-width: 200px;
  height: 125px;
}
.credit-card-brand-img {
  width: 46px;
  height: 28px;
}
.numberdot-icon {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 9999px;
  width: 6px;
  height: 6px;
}
.suspend-sus {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
  color: rgba(150, 159, 161, 1);
  min-width: 160px;
}
.apply-coupan-btn {
  --tw-border-opacity: 1;
  border-radius: 16px;
  border: 2px solid rgba(150, 159, 161, var(--tw-border-opacity));
  box-sizing: border-box;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 2.5;
}
.apply-coupan-btn:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.practice-payments-list-header {
  border-radius: 16px;
  display: grid;
  padding: 16px 12px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr) 24px;
}
.practice-payments-list-header > * {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  --tw-text-opacity: 1;
  color: rgba(77, 75, 75, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.38;
}
.payment-list-item {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
  border-radius: 16px;
  display: grid;
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  grid-template-columns: repeat(4, 1fr) 24px;
}
.payment-list-item > * {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.29;
}
.practice-settings-section-title {
  display: grid;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 1);
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-family: Karla, Arial, sans-serif;
  font-size: 22px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.28;
}
.practice-account-password-Password {
  background-color: rgba(98, 98, 98, 1);
  border-radius: 9999px;
  width: 8px;
  height: 8px;
}
.edit-practice-btn-only-border {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.btn-default-layout {
  border-radius: 8px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
}
.btn-default-layout.edit-practice-btn-only-border {
  border: 2px solid #000;
}
.library-libraryGrid {
  display: grid;
  padding-bottom: 150px;
  width: 100%;
  gap: 24px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.library-librarygrid-Item {
  grid-column: span 3 / span 3;
}
.library-cardbody {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  gap: 12px;
}
.library-cardactionbox {
  opacity: 0;
  position: absolute;
  right: 16px;
  bottom: 24px;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.library-content-btn {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: stretch;
  padding: 8px 12px;
  gap: 12px;
}
.library-cardbody:hover .library-cardactionbox {
  opacity: 1;
}
.library-edit-btn {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
}
.library-video-title {
  display: block;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.35;
}
.page-nav-item {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 9999px;
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  gap: 12px;
  height: 60px;
}
.page-nav-item-disable {
  cursor: not-allowed;
  pointer-events: none;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  background-color: transparent;
  box-shadow: none;
}
.Separator_column {
  margin-left: 48px;
  margin-right: 48px;
  width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgba(214, 214, 214, var(--tw-bg-opacity));
}
.photo-label-title {
  font-family: Karla, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}

.contact-label-title {
  font-family: Karla, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.44;
}
.team-photo-upload-box {
  --tw-border-opacity: 1;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 320px;
}
.grid-team-popup {
  width: 502px;
  max-width: 100%;
}
.grid-team-popup-lh {
  width: 255px;
  max-width: 100%;
}
.team-photo-instruciton {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0px;
}
.team-photo-instruciton-body {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
}
.team-member-thumb-example {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.team-photo-instruciton-body-img {
  max-width: 100%;
  display: block;
  margin: 0px;
  border: none;
  padding: 0px;
}
.playlist-container-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
}
.playlist-holder-box {
  background-color: transparent;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
}
.playlist-item-box {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-radius: 24px;
  display: flex;
  padding: 8px;
  position: relative;
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  gap: 16px;
  height: 111px;
}
.playlist-item-box-img {
  flex-shrink: 0;
  width: 169px;
}
.playlist-item-content-edit {
  opacity: 0;
  position: absolute;
  right: 8px;
  bottom: 8px;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.playlist-item-content-btns-wrap {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
  border-radius: 9999px;
  display: flex;
  align-items: stretch;
  padding: 8px 12px;
  gap: 12px;
}
.play-list-edit-remove-btns {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  --tw-text-opacity: 1;
  color: rgba(98, 98, 98, var(--tw-text-opacity));
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  line-height: 1.17;
}
.playlist-holder-box:hover .playlist-item-content-edit {
  opacity: 1;
}
.playlist-video-title-box {
  display: block;
  position: relative;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.35;
}
.playlist-video-title-box-inner {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}
.playlist-head-doted {
  height: 8px;
  width: 8px;
  border-radius: 9999px;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-family: Karla, Arial, sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.2;
}
.playlist-head-doted-color1 {
  background-color: rgba(77, 199, 191, 1);
}
.playlist-head-doted-color2 {
  background-color: rgba(234, 184, 8, 1);
}
.playlist-head-doted-color3 {
  background-color: rgba(232, 81, 81, 1);
}
.playlist-wrapper-body {
  width: 100%;
  height: 100%;
}
.playlist-wrapper-body-content {
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 150px;
  margin-top: 0px;
}

.gap-2 {
  gap: 16px !important;
}

.w-1\/2 {
  width: 50% !important;
}
.flex-1 {
  display: flex !important;
}

.index-StyledButton_cls2__1Drrf.index-StyledButton_size-small__EOSry {
  padding-left: 16px;
  padding-right: 16px;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  height: 36px;
}
.index-StyledButton_cls2__1Drrf.index-StyledButton_variant-primary__xNYe7:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 75, 75, var(--tw-bg-opacity));
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.index-StyledButton_cls2__1Drrf.index-StyledButton_variant-ghost__1dOzQ {
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.index-StyledButton_cls2__1Drrf {
  border-radius: 8px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  // padding-left: 24px;
  // padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
}

.index-StyledButton_cls2__1Drrf:hover {
  cursor: pointer;
}
.index-StyledButton_cls2__1Drrf.index-StyledButton_variant-primary__xNYe7:disabled {
  --tw-bg-opacity: 1;
  background-color: rgba(77, 75, 75, var(--tw-bg-opacity));
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(175, 175, 175, var(--tw-text-opacity));
}

.index-StyledButton_cls2__1Drrf.index-StyledButton_variant-primary__xNYe7 {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.tv-page-grid-wrap {
  display: grid;
  width: 100%;
  column-gap: 24px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-template-rows: -webkit-min-content -webkit-min-content 453px;
  grid-template-rows: min-content min-content 453px;
}
.tv-page-grid-wrap-container > * {
  max-height: 100%;
}
.device-tv-screen {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  margin: 0px;
}
.device-tv-screen-content {
  box-sizing: border-box;
  display: block;
  max-width: 100%;
}
.device-tv-screen-display {
  max-width: 100%;
  display: block;
  margin: 0px;
  border: none;
  padding: 0px;
}
.device-tv-screen-content .rounded-t-large {
  position: absolute;
  inset: 0px;
  box-sizing: border-box;
  padding: 0px;
  border: none;
  margin: auto;
  display: block;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}
.btn-layout-border.btn-h-36 {
  height: 36px;
  padding-left: 20px;

  padding-right: 20px;
}

.tv-smat-column {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-radius: 24px;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  --tw-shadow: 0px 12px 32px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}

.v-bs-resume-subs {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 16px;
  display: flex;
  align-items: stretch;
  padding: 16px;
  position: relative;
  box-shadow: 0 0 transparent, 0 0 transparent, 0px 8px 24px rgb(0 0 0 / 48%);
  color: rgba(255, 255, 255, 1);
  gap: 12px;
  -webkit-animation: notification-StyledNotification_fade-in__t7UTm 0.3s ease;
  animation: notification-StyledNotification_fade-in__t7UTm 0.3s ease;
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-transform: none;
  line-height: 1.32;
}
.v-bs-resume-subs:before {
  background-color: rgba(232, 81, 81, 1);
  content: '';
  width: 4px;
  border-radius: 26px;
}
.rs-v-bs-btn {
  background-color: transparent;
  border-width: 0;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  color: rgba(98, 98, 98, 1);
  font-family: Mulish, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.17;
  border-radius: 8px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 2px solid transparent;
  outline-offset: 2px;
  white-space: nowrap;
  color: rgba(77, 199, 191, 1);
}

.cancel-btn-rm-vs:hover {
  border-color: rgba(214, 214, 214, 1);
  color: rgba(175, 175, 175, 1);
}
.resume-btn-rm-vs:hover {
  color: rgba(175, 175, 175, 1);
}
.cancel-btn-rm-vs {
  border-radius: 9999px;
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  border-color: black;
  color: black;
  background-color: transparent;
  border-width: 2px;
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
}
.resume-btn-rm-vs {
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.card-form-CardWrapper_update_f {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
  border-radius: 32px;
  display: flex;
  padding: 24px;
  position: relative;
  height: 350px;
}
.card-form-CardShape_update_f {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-radius: 32px;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  gap: 16px;
  width: 361px;
  height: 225px;
}
.index-BrandImage_update_card {
  width: 46px;
  height: 28px;
}
.card-form-StripeInput_update_card {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.card-form-StripeInput_update_card > * {
  flex-grow: 1;
}
.card-form-CardShape_update_f {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(245, 245, 245, var(--tw-border-opacity));
  border-radius: 32px;
  border-width: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  gap: 16px;
  width: 361px;
  height: 225px;
}
.update-card-vs-bs-btn {
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 transparent),
    var(--tw-ring-shadow, 0 0 transparent), var(--tw-shadow);
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.Separator_footer {
  margin-top: 24px;
  margin-bottom: 45px;
  height: 1px;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgba(214, 214, 214, var(--tw-bg-opacity));
}
.fixed .focus\:outline-none:focus,
.fixed .outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  position: relative;
  z-index: 9999;
}
.SelectValue_cls2__34ypU {
  font-family: Karla, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.28;
}
.SelectValue_cls2__34ypU:focus {
  outline: 1px dotted;
  outline: 0;
}
.search-libray-show {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  border-width: 2px;
  padding: 8px 16px;
  width: 100%;
  height: 48px;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  transition-duration: 0.3s;
  font-family: Karla, Arial, sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.32;
  --tw-bg-opacity: 1;
  background-color: rgba(242, 245, 250, var(--tw-bg-opacity));
  border-radius: 9999px;
}
.search-libray-show-btn {
  border: 4px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-left: 0;
  padding-right: 0;
  width: 48px;
  background-color: transparent;
  white-space: nowrap;
  font-family: Mulish, Arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-transform: none;
  line-height: 1.36;
  border-radius: 9999px;
}
.search-libray-show-btn:focus {
  outline: 1px dotted;
  outline: 0;
}
.iconImg {
  height: 140px;
  width: 140px;
}
